$boring-green: #66bb6a;
$boring-green-03: rgba(102, 187, 106, 0.3);
$olive-green: #388d3c;

$focused-blue: #117cef;
$primary-button-blue: #3699FF;
$bright-blue: #007bff;
$cobalt-blue: #2664AD;
//$cornflower-blue: #5867dd;
$button-blue: #2196F3;
$dark-blue: #2564AD;
$button-blue-03: rgba(33, 150, 243, 0.3);
//$topaz: #17a2b8;
$mid-green: #4caf50;
$mid-green-01: rgba(76, 175, 80, 0.1);
$mid-green-08: rgba(76, 175, 80, 0.8);
$greenblue: #20c997;
$dark-seafoam: #1db386;
$warm-pink: #f4516c;
//$pinkish-red: #ef114a;
$lipstick: #d10f3f;
$mid-red: #ef5350;
$hot-steal: #dc3545;
$hot-warning: #F64E60;
$hot-warning-hover: #FFE2E5;
$red-light: #FEEDEF;
$red-gray: #E6D5D7;
$mid-red-03: rgba(239, 83, 80, 0.3);
$mid-red-01: rgba(239, 83, 80, 0.1);
//$macaroni-and-cheese: #ebb42a;
//$grey-button: #D7DAE7;
$disable-button: #d5dce4;
$disable-button-text: #a1aab5;
$action-text: #7E8299;

$white: #ffffff;
$white-two: #fafafa;
$white-three: #d8e6d4;
$white-four: #c6c8db;
$white-five: #f1f2f6;
$white-six: #f4f4fb;
$white-seven: #eaeaf8;
$white-eight: #F7FAFD;

$pale-grey: #f2f3f8;
$pale-grey-one: #EEF0F8;
$pale-grey-two: #ebedf2;
$pale-grey-three: #f8f9fa;
//$pale-grey-four: #f3f8fc;
$pale-grey-five: #e9ebf0;
$pale-grey-six: #f5f7fa;
//$pale-grey-seven: #edeff2;
//$pale-grey-eight: #e1e3e8;
//$pale-grey-nine: #dadde5;
//$pale-grey-ten: #ebedf0;
//$pale-grey-eleven: #d8dfe5;
//$pale-grey-twelve: #dbdcec;
$pale-grey-scroll: #A9A9A9;
$pale-grey-text: #848BA5;

$silver: #cdcfd4;
//$silver-two: #dcdee0;
//$silver-three: #d7d9de;
//$silver-four: #e1e3e5;
$silver-five: #cfd8dc;
$silver-six: #f4f5f9;
$silver-seven: #e7e8ee;
$silver-eight: #e7e7e7;
$silver-nine: #989ea2;
$silver-ten: #e8eaef;
$silver-eleven: #d5d3e3;
$silver-twelve: #E5ECF5;
//$silver-twelve: #eaeaf8;

$rabbit-one: #898B9B;
//$cloudy-blue: #afbfcc;
$cool-grey: #9aa3ab;
//$cool-grey-two: #8a9299;
$cool-grey-33: rgba(87, 96, 111, 0.2);
$light-grey: rgba(82, 63, 105, 0.16);
$steel: #747a9a;
$steel-one: #39425D;
//$grey-blue: #657894;
$battleship-grey: #848b92;
$header-grey: #B5B5C3;
$row-text-grey: #3F4254;
$row-border-grey: #EBEDF3;
$text-dark: #261127;
$table-border: #d8e3f0;
$table-hover: #edf2f9;

//$slate-blue: #60799c;
//$slate-grey: #5a5c6e;
$slate: #4e4c65;
$slate-one: #4d5278;
//$slate-two: #3b4757;
//$slate-three: #495463;
//$slate-four: #505f75;
//$slate-five: #49576b;
$slate-six: #455466;
//$slate-seven: #576780;
$slate-eight: #1b2139;
$slate-nine: #424950;
$slate-ten: #848484;
$slate-eleven: #454a6e;

$storm-sky: #78829D;

$beaver-one: #3F446A;
$beaver-two: #4C5177;
$rabbit-three: #2a2f53;

$charcoal-grey: #343a40;
//$charcoal-grey-two: #373a4f;
//$charcoal-grey-three: #343e4d;
$charcoal-grey-four: #384252;
//$charcoal-grey-five: #343c49;
//$charcoal-grey-six: #333547;
$charcoal-grey-seven-33: rgba(34, 45, 64, 0.6);

//$dark-lighter: #333547;
//$dark-two: #2f3845;
$dark: #2c2e3e;
$dark-three: #232933;
//$dark-four: #2c3440;
$dark-five: #2B3054;
$dark-six: #343A61;
//$dark-grey-blue: #334052;
//$dark-grey-blue-two: #364357;
$dark-breadcrumbs: #181C32;

$night-one: #8294d833;
$night-two: #2e3650;
$night-three: #8294d8;
$night-four: #1A233A;

//$darkness-one: #1a1f2f;
//$darkness-two: #0f131e;
//$darkness-three: #222840;

//$darkness-step-one: #282F48;
$darkness-step-two: #353D5A;
//$darkness-step-three: #355875;

$green-one: #66BB6A;
$green-avatar: #C9F7F5;
$green-text: #1BC5BD;
$green-dark: #378D3C;
$green-light: #B2E8E7;
$green-lighter: #E9F1E7;
$green-light-row: #EAFCFC;
$green-light-bg: #C8F1EF;

$yellow-light: #FFF1C2;
$yellow: #FEC300;

$blue-light: #CFE7FF;
$blue-one: #40c4ff;
$blue-two: #4f71ea;
$blue-three: #0088FF;
$blue-four: #337AB7;
$checkbox-bg: #3f51b5;

$light-shadow: #523F690D;
$light-shadow2: #523F6942;
$light-shadow3: #523F6929;
$light-shadow4: #2611271F;
$light-shadow5: #00000029;

$avatar: #E1F0FF;
$row-bg-hover: #F3F6F9;
$row-bg-hover-5: rgba(243, 246, 249, 0.5);
$button-bg-action: #E6EBF0;
$text-button: #F4F4FB;
$inside-border: #e9e7f7;
$patient-bg: #F4F9FF;
$row-highlight: #F4F6F9;

$left-panel-light-bg: #1E1E2D;
$left-panel-dark-bg: #1E1E2D;
$menu-item: #a2a3b7;
$disabled-bg: #F9F9F9;
$disabled-border: #F9F9F9;

$withard-number-bg: #3699FF14;

$status-available: #FFC300;
$status-offered: #FFC300;
$status-requested: #00BCD4;
$status-scheduled: #1E88E5;
$status-en-route: #00E676;
$status-in-progress: #43A047;
$status-missed: #D81B60;
$status-canceled: #E53935;
$status-no-show: #E53935;
$status-completed: #9E9E9E;

$orange: #ffa800;
$orange-ligth: #ffd285;
$brown: #f57c00;
$brown-light: #ffe0b2;
$black: #000;
$scroll-thumb: #cbcbcb;
$peach-red: #F3615D;
